import {
    ActionCounts,
    LegacyV2ActionSchema,
    NextActionContext,
    ReferralsFilter,
    SnoozedStatus,
    SortOrder,
} from '$types';
import { SnoozeStatus } from './snooze';
import { CountryCode } from '@/utils/phone';

/**
 * Config
 */

type Env = 'development' | 'staging' | 'production';

export enum ConfigStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    LOADED = 'loaded',
}

export interface ConfigState {
    status: ConfigStatus;
    refreshing: boolean;
    api_version?: string;
    config?: Config;
}

export interface ConfigLoadedState extends ConfigState {
    status: ConfigStatus.LOADED;
    config: Config;
}

export interface InitialConfigState extends ConfigState {
    status: ConfigStatus.IDLE | ConfigStatus.LOADING;
}

export interface Config {
    api_hostname: string;
    api_base_url: string;
    ui_base_url: string;
    proxy_base_url: string;
    environment: Env;
    ws_port: '6001';
    ws_secure: boolean;
    pusher_host: string;
    pusher_enabled: boolean;
    pusher_app_id: 'leadflo-dev' | 'leadflo-staging' | 'leadflo-production';
    pusher_cluster: 'leadflo';
    pusher_key: string;
    novu_app_id: string;
    auth_mode: 'jwt' | 'session';
}

/**
 * Auth
 */

export interface AuthArgs {
    email: string;
    password: string;
}

export enum AuthStatus {
    UNAUTHED = 'unauthed',
    AUTHED = 'authed',
    AUTH_ERRORED = 'auth_errored',
}

export type Currency = {
    code: string;
    symbol: string;
    name: string;
};

export interface AuthState {
    status: AuthStatus;
    error: string | null;
    token?: string;
    role?: string;
    txTypes?: string[];
    subscriberId?: string | null;
    subscriberHash?: string | null;
    currency?: Currency | null;
}

export interface AuthLoadedState extends AuthState {
    status: AuthStatus.AUTHED;
    error: null;
    token: string;
    role: string;
    txTypes: string[];
    subscriberId: string;
    currency: Currency;
}

export interface AuthInitialState extends AuthState {
    status: AuthStatus.UNAUTHED | AuthStatus.AUTH_ERRORED;
    error: string | null;
}

export interface Client {
    id: number;
    name: string;
    settings: ClientSettings;
    country: CountryCode;
}

export interface ClientSettings {
    sms_practice_alerts: boolean;
    sms_weekday_alert: string | null;
    sms_weekend_alert: string | null;
    sms_weekooh_alert: string | null;
    pipeline: string;
    sms_enabled: boolean;
    email_enabled: boolean;
    referrals_enabled: boolean;
}

/**
 * Features
 */

export enum FeatureStatus {
    IDLE = 'idle',
    LOADED = 'loaded',
}

export interface FeatureState {
    status: FeatureStatus;
    features: string[];
}

export interface FeatureIdleState {
    status: FeatureStatus.IDLE;
    features: [];
}

export interface FeatureLoadedState {
    status: FeatureStatus.LOADED;
    features: string[];
}

/**
 * Actions
 */

export enum ActionStatus {
    OK = 'ok',
    INITIAL_LOAD = 'initial_load',
    REFRESHING = 'refreshing',
    ERROR_INITIAL_LOADING = 'error_initial_loading',
    ERROR_REFRESHING = 'error_refreshing',
}

export interface ActionViewMeta {
    state: ActionStatus;
    section: 'Leads' | 'Consultations';
    path: string;
    categories: {
        name: string;
        stages: string[];
        displayTotal: number; // The maximum actions to display in this category
        actions: string[];
    }[];
    query?: ActionsQuery;
}

export interface BaseActionRecord {
    initialized: boolean;
    patient_id: string;
}

/**
 * A shell action is a record of an action, usually that we know of
 * from a websocket event (see actionUpdated), but isn't suitable
 * for display
 */
interface ShellAction extends BaseActionRecord {
    initialized: false;
    stage: string;
}

export type Action = LegacyV2ActionSchema | ShellAction;

/**
 * The current state of an action record.
 *
 * Open and closed are self explanatory. These are actions that are ready to be
 * completed by a user.
 *
 * An action is marked as hidden after it has been completed (or, in the
 * future,
 * if there's been an error and we want to hide it). We can't just delete it as
 * we need to keep it around so we aren't notifying the user of updates to an
 * action they just updated.
 *
 * When a user has completed an action, it will be marked as complete.
 * When they close the complete dialog, the action will change to hidden.
 */
export type ActionRecordState =
    | 'hidden'
    | 'complete'
    | 'open'
    | 'closed'
    | 'snoozed'
    | 'snoozeExtended';

export interface ActionRecord<T extends BaseActionRecord = Action> {
    // Either a fully initialized, ready to display action, or a shell
    action: T;

    state: ActionRecordState;

    // An action will be marked as stale (see actionUpdated event) when the
    // version of the action in the store is an older version of the one from
    // the API, as notified by websocket. The action should be refreshed.
    stale: boolean;

    isNew: boolean;

    isWaking: boolean;
}

export interface NewActionState {
    isDisplayed: boolean;
}

export interface ActionState {
    newActions: {
        [patientID: string]: NewActionState;
    };
    actions: {
        [patientID: string]: ActionRecord;
    };
    views: {
        [view: string]: ActionViewMeta;
    };
    counts: ActionCounts;
    sort: SortOrder;
    filters: {
        referrals: ReferralsFilter;
        snooze: SnoozedStatus;
    };
}

export interface ActionsQuery {
    types?: string[] | null;
    labels?: string[] | null;
    sources?: string[] | null;
    range?: {
        start: string;
        end: string;
    };
    includeSnoozed?: boolean;
    referrals?: ReferralsFilter;
}

export type SearchState = 'start' | 'searching' | 'found' | 'not_found';

export interface SearchResult {
    id: string;
    name: string;
    email: string;
    phone: string;
}

export interface PatientState {
    search: {
        state: SearchState;
        results?: Array<SearchResult>;
    };
    editor: PatientEditorState;
    records: PatientMap;
}

export interface PatientMap {
    [id: string]: PatientRecordState;
}

export interface PatientEditorState {
    open: boolean;
    patientId: string | null;
}

export interface PatientEditorOpenState extends PatientEditorState {
    open: true;
    patientId: string;
    contactId: number;
}

export interface PatientEditorClosedState extends PatientEditorState {
    open: false;
    patientId: null;
    contactId: null;
}

export interface PatientRecordState {
    status: PatientStatus;
    patient?: Patient;
    failedActionPayload?: NextActionContext;
}

export interface PatientRecordLoadedState {
    status:
        | PatientStatus.LOADED
        | PatientStatus.SAVING
        | PatientStatus.ERRORED_SAVING_ACTION;
    patient: Patient;
}

export interface PatientRecordNotLoadedState {
    status:
        | PatientStatus.IDLE
        | PatientStatus.LOADING
        | PatientStatus.ERRORED
        | PatientStatus.ERRORED_SAVING_ACTION
        | PatientStatus.ERRORED_SAVING;
}

export enum PatientStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    ERRORED = 'errored',
    LOADED = 'loaded',
    SAVING = 'saving',
    ERRORED_SAVING = 'errored_saving',
    ERRORED_SAVING_ACTION = 'errored_saving_action',
}

export enum GDPRStatus {
    OptedIn = 'Opted In',
    OptedOut = 'Opted Out',
    Undecided = 'Undecided',
}

type PhoneNumberStatus = 'number_invalid' | 'number_unreachable' | null;

export interface Patient {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    gdpr: GDPRStatus;
    labels: string[];
    type: string;
    stage: string | null;
    next_action_at?: string;
    phone_number: {
        number: string;
        invalid_reason: PhoneNumberStatus;
        invalidated_at: string | null;
    } | null;
    source: 'Unknown' | string;
    referrer_email: string | null;
    referrer_name: string | null;
    snooze: SnoozeStatus | null;
    value: {
        proposed: string;
        accepted: string;
    };
}

export type Value = {
    proposed: string;
    accepted: string;
    paid: string;
};

type ManualValueState = {
    state: 'manual';
    value: Value;
};

type UnlinkedValueState = {
    state: 'unlinked';
};

type SyncingValueState = {
    state: 'syncing';
};

type SyncedValueState = {
    state: 'synced';
    value: Value;
};

export type ValueState =
    | ManualValueState
    | UnlinkedValueState
    | SyncingValueState
    | SyncedValueState;

import * as api from '$api/patients';
import { api as http } from '$api/api';
import {
    createAction,
    createAsyncThunk,
    createReducer,
    createSelector,
} from '@reduxjs/toolkit';
import { Patient, PatientState, PatientStatus, ValueState } from '../types';
import { fetchTimeline, sendComm } from './timeline';
import * as queries from '$state/queries/patient';
import { RootState } from '$state/store';
import { actionCompleted } from '$state/events';
import { loadContext } from '$state/concerns/contexts';
import { addLabel, addRecentLabel, selectClientCountry } from './client';
import { phone } from '$utils';
import { Stage } from './stages';
import * as toasts from '$ui/Flo/ToastV2';

// Actions

type OpenPatientEditorArgs = {
    patientId: string;
};

export const openPatient = createAction<OpenPatientEditorArgs>('patients/open');

export const closePatient = createAction('patients/close');

export const search = createAsyncThunk('patients/search', (query: string) => {
    return api.search(query);
});

export const clearSearch = createAction('patients/search/cear');

type UpdatePatientValueArgs = {
    patientID: string;
    accepted: string;
    proposed: string;
};

export const updatePatientValue =
    createAction<UpdatePatientValueArgs>('patients/value');

export const fetchPatient = createAsyncThunk<Patient, string>(
    'patients/fetch',
    (patientId: string): Promise<Patient> => {
        return api.fetchPatient(patientId);
    },
);

export const savePatient = createAsyncThunk<void, Patient>(
    'patients/save',
    async (patient: Patient, thunk): Promise<void> => {
        const failedAction = queries.get(patient.id)(
            thunk.getState() as RootState,
        ).failedActionPayload;

        if (failedAction) {
            thunk.dispatch(saveAction({ id: patient.id, ...failedAction }));
            return;
        }

        await api.savePatient(patient).then(() => {
            thunk.dispatch(fetchPatient(patient.id));
        });

        thunk.dispatch(loadContext(patient.id));
    },
);

export const saveAction = createAsyncThunk<void, api.NextActionPayload>(
    'patients/saveAction',
    async (payload: api.NextActionPayload, thunk) => {
        await api.saveAction(payload);

        thunk.dispatch(
            actionCompleted({
                patientId: payload.id,
            }),
        );

        thunk.dispatch(fetchPatient(payload.id));
        thunk.dispatch(fetchTimeline(payload.id));
    },
);

// Editable labels
interface LabelPayload {
    patientId: string;
    label: string;
}

export const unlabel = createAsyncThunk<void, LabelPayload>(
    'patients/unlabel',
    async ({ patientId, label }: LabelPayload) => {
        await api.unlabel(patientId, label);
    },
);

export const label = createAsyncThunk<void, LabelPayload>(
    'patients/label',
    async ({ patientId, label }: LabelPayload, thunkAPI) => {
        thunkAPI.dispatch(addRecentLabel(label)); // current label is already
        // created just update state
        await api.label(patientId, label).then(() => {
            thunkAPI.dispatch(addLabel(label));
        });
    },
);

interface ChangeStagePayload {
    patientId: string;
    stage: Stage;
    note: string | null;
    patientName: string;
}

export const changeStageWithNote = createAsyncThunk<void, ChangeStagePayload>(
    'patients/changeStage',
    async (
        { patientId, stage, note, patientName }: ChangeStagePayload,
        thunkAPI,
    ) => {
        await api.changeStageWithNote(patientId, stage, note);
        toasts.success({
            id: 'change-stage',
            message: `${patientName} moved to ${stage.name}`,
        });
        thunkAPI.dispatch(fetchPatient(patientId));
        thunkAPI.dispatch(fetchTimeline(patientId));
    },
);

interface SnoozePayload {
    patientId: string;
    endsAt: string;
    comment?: string | null;
}

export const startSnooze = createAsyncThunk<void, SnoozePayload>(
    'patients/snooze',
    async ({ patientId, endsAt, comment }: SnoozePayload) => {
        await http.put(`/v3/patients/${patientId}/snooze`, {
            ends_at: endsAt,
            comment,
        });
    },
);

export const extendSnooze = createAsyncThunk<void, SnoozePayload>(
    'patients/snooze/extend',
    async ({ patientId, endsAt, comment }: SnoozePayload) => {
        await http.post(`/v3/patients/${patientId}/snooze`, {
            ends_at: endsAt,
            comment,
        });
    },
);

export const endSnooze = createAsyncThunk<void, string>(
    'patients/snooze/end',
    async (patientId: string) => {
        await http.delete(`/v3/patients/${patientId}/snooze`);
    },
);

// Initial state

const initialState: PatientState = {
    search: {
        state: 'start',
    },
    editor: {
        open: false,
        patientId: null,
    },
    records: {},
};

// Reducer

export default createReducer(initialState, (builder) => {
    builder
        .addCase(fetchPatient.pending, (state, action) => {
            const { arg: patientId } = action.meta;
            const record = state.records[patientId];
            if (record && record.status === PatientStatus.LOADED) {
                return;
            }
            state.records[patientId] = {
                ...record,
                status: PatientStatus.LOADING,
            };
        })

        .addCase(fetchPatient.fulfilled, (state, action) => {
            const { arg: patientId } = action.meta;
            const record = state.records[patientId];
            record.patient = { ...record.patient, ...action.payload };
            record.status = PatientStatus.LOADED;
        })

        .addCase(fetchPatient.rejected, (state, action) => {
            const { arg: patientId } = action.meta;
            state.records[patientId].status = PatientStatus.ERRORED;
        })

        .addCase(savePatient.pending, (state, action) => {
            const { arg: patient } = action.meta;
            state.records[patient.id].patient = patient;
            state.records[patient.id].status = PatientStatus.SAVING;
        })

        .addCase(savePatient.rejected, (state, action) => {
            const { arg: patient } = action.meta;
            state.records[patient.id].status = PatientStatus.ERRORED_SAVING;
        })

        .addCase(savePatient.fulfilled, (state, action) => {
            const { arg: patient } = action.meta;
            state.records[patient.id].status = PatientStatus.LOADED;
        })

        .addCase(saveAction.pending, (state, action) => {
            const { arg: payload } = action.meta;
            state.records[payload.id].status = PatientStatus.SAVING;
            state.records[payload.id].failedActionPayload = undefined;
        })

        .addCase(saveAction.fulfilled, (state, action) => {
            const { arg: payload } = action.meta;
            state.records[payload.id].status = PatientStatus.LOADED;
            state.records[payload.id].failedActionPayload = undefined;
        })

        .addCase(saveAction.rejected, (state, action) => {
            const { arg: payload } = action.meta;
            state.records[payload.id].status =
                PatientStatus.ERRORED_SAVING_ACTION;
            state.records[payload.id].failedActionPayload = payload;
        })

        .addCase(openPatient, (state, action) => {
            state.editor.open = true;

            const { patientId } = action.payload;
            state.editor.patientId = patientId;

            if (!state.records[patientId]) {
                state.records[patientId] = {
                    status: PatientStatus.IDLE,
                };
            }
        })
        .addCase(closePatient, (state) => {
            state.editor.open = false;
            state.editor.patientId = null;
        })

        .addCase(clearSearch, (state) => {
            state.search.state = 'start';
        })

        .addCase(search.pending, (state) => {
            state.search.state = 'searching';
        })

        .addCase(search.fulfilled, (state, action) => {
            const { payload: results } = action;

            // search has been cancelled
            if (state.search.state === 'start') {
                return;
            }

            state.search.state = results.length > 0 ? 'found' : 'not_found';
            state.search.results = results.map((result) => ({
                id: result.id,
                name: [result.first_name, result.last_name].join(' '),
                email: result.email,
                phone: result.phone,
            }));
        })

        .addCase(search.rejected, (state) => {
            // search has been cancelled
            if (state.search.state === 'start') {
                return;
            }

            state.search.state = 'not_found';
        })

        .addCase(unlabel.pending, (state, action) => {
            const {
                arg: { patientId, label },
            } = action.meta;

            const record = state.records[patientId];

            if (!record?.patient) {
                return;
            }

            const { patient } = record;
            patient.labels = patient.labels.filter((l) => l !== label);
        })

        .addCase(label.pending, (state, action) => {
            const {
                arg: { patientId, label },
            } = action.meta;

            const record = state.records[patientId];
            if (!record?.patient) {
                return;
            }

            const { patient } = record;

            if (
                patient.labels.some(
                    (l) => l.toLowerCase() === label.toLowerCase(),
                )
            ) {
                return;
            }

            patient.labels = [...patient.labels, label];
        })

        .addCase(startSnooze.pending, (state, action) => {
            const {
                arg: { patientId, endsAt, comment },
            } = action.meta;

            const record = state.records[patientId];
            if (!record?.patient) {
                return;
            }

            const { patient } = record;

            patient.snooze = {
                ends_at: endsAt,
                comment: comment,
                state: 'asleep',
            };
        })

        .addCase(extendSnooze.pending, (state, action) => {
            const {
                arg: { patientId, endsAt, comment },
            } = action.meta;

            const record = state.records[patientId];
            if (!record?.patient) {
                return;
            }

            const { patient } = record;

            patient.snooze = {
                ends_at: endsAt,
                comment: comment,
                state: 'asleep',
            };
        })

        .addCase(endSnooze.pending, (state, action) => {
            const { arg: patientId } = action.meta;

            const record = state.records[patientId];
            if (!record?.patient) {
                return;
            }

            const { patient } = record;

            patient.snooze = null;
        })

        .addCase(sendComm.pending, (state, action) => {
            const {
                arg: { patientId, endSnooze },
            } = action.meta;

            const record = state.records[patientId];
            if (!record?.patient) {
                return;
            }

            const { patient } = record;

            if (endSnooze) {
                patient.snooze = null;
            }
        })

        .addCase(changeStageWithNote.pending, (state, action) => {
            const {
                arg: { patientId, stage },
            } = action.meta;

            const record = state.records[patientId];
            if (!record?.patient) {
                return;
            }

            const { patient } = record;

            patient.stage = stage.key;
        })

        .addCase(updatePatientValue, (state, action) => {
            const { patientID, accepted, proposed } = action.payload;
            const patient = state.records.patients[patientID]?.patient;

            if (!patient) {
                return;
            }

            patient.value = { proposed, accepted };
        });
});

// Selectors

export const selectSearch = (state: RootState) => state.patient.search;

export const searchState = createSelector([selectSearch], ({ state }) => state);

export const searchResults = createSelector(
    [selectSearch, selectClientCountry],
    ({ results }, country) => {
        if (!results) {
            return [];
        }

        return results.map((result) => ({
            ...result,
            phone: phone.format(result.phone, country),
        }));
    },
);

export const selectPatientValue = createSelector(
    [
        (state: RootState) => state.patient.records,
        (_, { id }: { id: string }) => id,
    ],
    (patients, id): ValueState => {
        const value = patients[id]?.patient?.value;

        if (!value) {
            return {
                state: 'manual',
                value: {
                    paid: '0.00',
                    proposed: '0.00',
                    accepted: '0.00',
                },
            };
        }

        return {
            state: 'manual',
            value: {
                paid: '0.00',
                ...value,
            },
        };
    },
);

import { mix } from '$ui/Flo/util';
import React from 'react';
import styled, { css } from 'styled-components';
import { Hue, Shade } from '$ui/Flo/types';
import { money } from '$utils';
import { Shadow } from '$ui/Flo/Shadow';

interface LoadingProps {
    state: 'loading';
}

interface UnlinkedProps {
    state: 'unlinked';
}

interface LoadedProps {
    state: 'loaded';
    values: Value[];
}

interface Value {
    key: string;
    value: string;
    hue?: Hue;
    shade?: Shade;
    prefix?: string;
}

export type Props = UnlinkedProps | LoadingProps | LoadedProps;

export const ValueBar = (props: Props) => {
    if (props.state === 'loading') {
        return (
            <div
                data-qa="financial-shadow"
                style={{
                    lineHeight: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Shadow shade="9" block rounded height={0.5} />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 2,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                        }}
                    >
                        <Shadow
                            shade="9"
                            block
                            rounded
                            width="10"
                            height={2.25}
                        />
                        <BarItemKey>Accepted</BarItemKey>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'right',
                            gap: '4px',
                        }}
                    >
                        <Shadow
                            shade="9"
                            block
                            rounded
                            width="10"
                            height={2.25}
                        />
                        <BarItemKey>Proposed</BarItemKey>
                    </div>
                </div>
            </div>
        );
    }

    if (props.state === 'unlinked') {
        return (
            <Wrapper zero>
                <Bar>
                    <BarItem width={100} hue="grey" shade="10" />
                </Bar>
                <LabelWrapper>
                    <LabelItem width={100} align="center">
                        <BarItemKey disabled>Patient not linked</BarItemKey>
                    </LabelItem>
                </LabelWrapper>
            </Wrapper>
        );
    }

    const values = props.values.filter((v) => {
        return v.value !== '0.00';
    });

    const total = values.reduce((acc, v) => {
        return acc + parseFloat(v.value);
    }, 0);

    if (total === 0) {
        return (
            <Wrapper zero>
                <Bar>
                    <BarItem width={100} hue="grey" shade="10" />
                </Bar>
                <LabelWrapper>
                    <LabelItem width={100} align="center">
                        <BarItemKey disabled>Value not given</BarItemKey>
                    </LabelItem>
                </LabelWrapper>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Bar>
                {values.map((value, index) => {
                    const percentage = (parseFloat(value.value) / total) * 100;
                    return (
                        <BarItem
                            key={'bar-' + index}
                            width={percentage}
                            hue={value.hue}
                            shade={value.shade}
                        ></BarItem>
                    );
                })}
            </Bar>
            <LabelWrapper>
                {values.map((value, index) => {
                    const percentage = (parseFloat(value.value) / total) * 100;
                    return (
                        <LabelItem
                            width={percentage}
                            key={'label-' + index}
                            align={alignment(index, values.length)}
                            hue={value.hue}
                        >
                            <BarItemLabel data-testid={`label-${value.key}`}>
                                {money.format({
                                    value: value.value,
                                    currency: value.prefix,
                                    compact: true,
                                    maximumSignificantDigits: 1,
                                })}
                            </BarItemLabel>
                            <BarItemKey>{value.key}</BarItemKey>
                        </LabelItem>
                    );
                })}
            </LabelWrapper>
        </Wrapper>
    );
};

const alignment = (index: number, length: number) => {
    if (index === 0) {
        return 'left';
    }

    if (index === length - 1) {
        return 'right';
    }

    return 'center';
};

const Wrapper = styled.div<{ zero?: boolean }>`
    ${mix.type({ level: 'body2' })}
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;

    ${({ zero }) =>
        !zero &&
        css`
            &:hover {
                cursor: pointer;
            }
        `};
`;

const Bar = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2px;
    ${mix.bg({ hue: 'grey', shade: '9', alpha: 0.5 })};
    border-radius: 4px;
`;

const BarItem = styled.div<{
    width: number;
    hue?: Hue;
    shade?: Shade;
}>`
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width}%;
    height: 4px;
    border-radius: 4px;
    ${({ hue, shade }) => css`
        ${mix.bg({
            hue: hue ?? 'grey',
            shade: shade ?? '5',
        })}
    `}
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`;

type LabelItemProps = {
    width: number;
    align: string;
    hue?: Hue;
};

const LabelItem = styled.div<LabelItemProps>`
    min-width: 100px;
    width: ${(props) => props.width}%;
    ${mix.type({ level: 'body1' })}
    font-weight: 600;
    letter-spacing: 1px;

    ${({ hue }) => css`
        color: ${mix.palette({
            hue: hue ?? 'grey',
            shade: '5',
        })};
    `}

    ${({ align }) => css`
        ${align === 'left' &&
        `
            margin-right: auto;
            text-align: left;
        `}
        ${align === 'right' &&
        `
            margin-left: auto;
            text-align: right;
        `}
        ${align !== 'right' &&
        align !== 'left' &&
        `
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        `}
    `}

    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 0.5 })};
`;

const BarItemKey = styled.div<{ disabled?: boolean }>`
    ${mix.type({ level: 'small', bold: true })}
    ${mix.color({ profile: 'body' })}
    text-transform: uppercase;
    font-weight: 500;
    margin: 0;
    line-height: 1;

    ${({ disabled }) => {
        return disabled && mix.color({ profile: 'disabled' });
    }}
`;

const BarItemLabel = styled.div`
    ${mix.type({ level: 'body1', bold: true })}
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
`;

import * as React from 'react';
import styled from 'styled-components';
import { applyPadding, mix, scale } from '$ui/Flo/util';
import { applyInputStyle } from '$ui/Flo/Field';
import { Shadow } from '$ui/Flo/Shadow';
import { Circle, Monogram } from '$ui/Flo/Monogram';
import { ColorToken } from '$ui/Flo/types';
import { FinancialBar, FinancialBreakdown } from '$ui/Dentally';
import { FinancialSummary as FinancialSummaryV2 } from '$ui/Dentally/V2';
import * as dentally from '$state/concerns/dentally/patients';
import { PatientFinancials } from '$state/concerns/dentally/patients';
import { ValueState } from '$state/types';

export interface NameBlockState {
    name: string;
}

export interface Props {
    state: NameBlockState;
    loading?: boolean;
    src?: string;
    dentallyState?: dentally.PatientState;
    financials?: PatientFinancials;
    currency?: string;
    valueState?: ValueState;
}

export const NameBlock = (props: Props) => {
    const { state, loading, src = null, dentallyState, financials } = props;

    return (
        <Wrapper>
            {src ? (
                <PatientImage
                    name={state.name}
                    src={src}
                    size={6}
                    color={'primary-500'}
                />
            ) : (
                <Monogram
                    color="gray-100"
                    fontColor="primary-500"
                    size={6}
                    name={state.name}
                    loading={loading}
                />
            )}
            <Fields>
                <div>
                    <NameField
                        id="patient-name-field"
                        value={state.name}
                        loading={loading}
                    />
                </div>
                {!loading && financials && (
                    <FinancialSummaryV2 financials={financials} />
                )}
                {!loading && dentallyState && (
                    <FinancialSummary dentallyState={dentallyState} />
                )}
            </Fields>
        </Wrapper>
    );
};

export interface Financial {
    dentallyState: dentally.PatientState;
}

const FinancialSummary = (props: Financial) => {
    const { dentallyState } = props;

    const [open, setOpen] = React.useState(false);

    if (!dentally.linked(dentallyState) && !dentally.loading(dentallyState)) {
        return null;
    }

    if (!dentally.synced(dentallyState)) {
        return <FinancialBar loading />;
    }

    const financial = dentally.financials(dentallyState);
    const { total } = financial;

    const handleOpen = () => {
        if (Number(total.paid) === 0 && Number(total.unpaid) === 0) {
            return;
        }

        setOpen(true);
    };

    const handleClose = () => {
        if (open) setOpen(false);
    };

    return (
        <div
            onMouseEnter={() => handleOpen()}
            onMouseLeave={() => handleClose()}
        >
            <FinancialBar
                paid={Number(total.paid)}
                potential={Number(total.unpaid)}
                currency="GBP"
            />

            {open && (
                <FinancialWrapper open={open}>
                    <FinancialBreakdown financial={financial} />
                </FinancialWrapper>
            )}
        </div>
    );
};

const FinancialWrapper = styled.div<{
    open: boolean;
}>`
    ${({ open }) => (open ? `display: block;` : `display: none;`)}
    position: absolute;
    height: auto;
    ${mix.padding({ padding: [2] })}
    z-index: 1001;
    left: 0;
    right: 0;
    transform: translateY(-12px);

    & > div {
        position: relative;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: white;
    }
`;

const Fields = styled.div`
    ${({ theme }) => applyPadding({ theme, padding: [0] })};
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${mix.padding({ padding: [2, 3] })};
    min-height: ${mix.unit({ size: 13.5 })};
    gap: ${mix.unit({ size: 2 })};
`;

// Name field
const NameField = (props: {
    id?: string;
    value: string;
    loading?: boolean;
}) => {
    const { value, loading } = props;

    if (loading) {
        return (
            <div style={{ height: '32px' }}>
                <Shadow
                    rounded
                    width={60}
                    height={4}
                    style={{ marginBottom: '8px' }}
                />
            </div>
        );
    }

    return (
        <Name data-cy="patient-name-field" bold level="h6" profile="body">
            {value}
        </Name>
    );
};

const Name = styled.div`
    ${applyInputStyle}
    ${({ theme }) => applyPadding({ theme, padding: [1, 0, 0, 0] })}

    cursor: initial;
    line-height: ${({ theme }) => scale(theme, 4)}px;
    border-radius: 50px 0 0 50px;
    position: relative;
    box-sizing: content-box;
    max-width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const PatientImage = (props: {
    name: string;
    src: string;
    size: number;
    color: ColorToken;
}) => {
    const { src, size, color, name } = props;

    return (
        <Circle size={size} color={color} data-qa="patient-image" hidden={true}>
            <Img src={src} alt={name ? name : 'Patient Image'} loading="lazy" />
        </Circle>
    );
};

const Img = styled.img`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

import { ValueBar } from '$ui/Flo/ValueBar';
import React from 'react';

interface LoadingProps {
    loading: true;
}

interface LoadedFinancialBarProps {
    loading?: false;
    currency: string;
    paid: number;
    potential: number;
}

export type FinancialBarProps = LoadingProps | LoadedFinancialBarProps;

export const FinancialBar = (props: FinancialBarProps) => {
    if (props.loading) {
        return <ValueBar state="loading" />;
    }

    const { currency, paid, potential } = props;

    return (
        <ValueBar
            state="loaded"
            values={[
                {
                    key: 'Paid',
                    value: paid.toFixed(2),
                    hue: 'green',
                    shade: '5',
                    prefix: currency,
                },
                {
                    key: 'Potential',
                    value: potential.toFixed(2),
                    hue: 'primary',
                    shade: '5',
                    prefix: currency,
                },
            ]}
        />
    );
};
